import React from 'react'
import Toast from "../../styles/Toast"
import { BillsPage } from "./Bills"
import ReCAPTCHA from "react-google-recaptcha"
import {captchaKey} from '../../config';

export const Bills = (props) => {
    const recaptchaRef = React.createRef();
    const deleteBillsRef = React.useRef(null)
    
    const openDeleteBillsToast = () => deleteBillsRef.current.open()

    return(
        <>
            <BillsPage {...props} recaptchaRef={recaptchaRef} openDeleteBillsToast={openDeleteBillsToast} />
            <ReCAPTCHA
                sitekey={captchaKey}
                size='invisible'
                ref={recaptchaRef}
            />
            <Toast
                type="success"
                message="Bills deleted successfully"
                ref={deleteBillsRef}
            />
        </>
    )
}