import React from 'react'
import { FlexBox, CenterText } from "../../styles/styleme"
import { ErrorMessage, SubmitButton, OTPSent, Process, PageTitle, CustomInput } from "../common/Contact"
import { PhoneInput, ButtonLink } from "./styles"
import { sendOtp, verifyOtp, resendOtp } from "../../api/actions"
import RZPBMLogo from "../../rzpbm-logo-Light.svg"

const resendCounter = 30

const PageOne = ({
	onSubmit,
	isSubmitting,
	pageOneValueOne,
	handlePageOneInputOne,
	errorOne,
}) => (
	<>
		<div style={{ width: "100%", textAlign: 'center' }}>
			<label htmlFor="phone-number">Please enter your mobile number</label>
			<br />
			<PhoneInput align="baseline">
				<div>
					+91
				</div>
				<CustomInput name="phone-number" id="phone-number" type="number" maxLength={10} onChange={handlePageOneInputOne} value={pageOneValueOne} />
			</PhoneInput>

			{
				errorOne &&
				<ErrorMessage text={errorOne} margin="-15px 0px 10px 5px" />
			}
		</div>
		<SubmitButton onClick={onSubmit} disabled={isSubmitting}>
			Send OTP
		</SubmitButton>
	</>
)

const PageTwo = ({
	onSubmit,
	isSubmitting,
	onPageTwoSecondarySubmit,
	onPageTwoTertiarySubmit,

	pageTwoValueOne,
	pageTwoValueThree,
	handlePageTwoInputOne,
	errorOne,

	isVisible,
}) => {
	const [timeLeft, setTimeLeft] = React.useState(resendCounter)

	React.useEffect(() => {
		if (isVisible)
			timeLeft > 0 && setTimeout(() => setTimeLeft(timeLeft - 1), 1000)
		else if (!isVisible)
			setTimeLeft(resendCounter)

	}, [timeLeft, isVisible])

	const handleResendOTP = (e) => {
		e.preventDefault()

		setTimeLeft(resendCounter)
		onPageTwoTertiarySubmit()
	}

	return (
		<>
			<div style={{ width: "100%", textAlign: 'center' }}>
				<label htmlFor="phone-number">
					Enter the OTP you have recieved on
					<br />
					<strong>+91 - {pageTwoValueThree}</strong> <ButtonLink onClick={onPageTwoSecondarySubmit}>change</ButtonLink>
				</label>
				<br />
				<PhoneInput align="baseline">
					<CustomInput
						name="otp"
						id="otp"
						type="number"
						onChange={handlePageTwoInputOne}
						value={pageTwoValueOne}
						maxLength={6}
					/>
				</PhoneInput>

				{
					errorOne &&
					<ErrorMessage text={errorOne} margin="-15px 0px 10px 5px" />
				}

				{
					timeLeft > 0
						? (
							<CenterText style={{ color: '#aaa' }}>
								Resend OTP <br /> in {timeLeft} seconds
							</CenterText>
						)
						: (
							<ButtonLink onClick={handleResendOTP}>
								Resend OTP
							</ButtonLink>
						)
				}
			</div>
			<SubmitButton onClick={onSubmit} disabled={isSubmitting}>
				Verify
			</SubmitButton>
		</>
	)
}

export const Login = ({ setVerified, phoneNumber, setPhoneNumber, getBillsCount, recaptchaRef }) => {
	const [pageNumber, setPageNumber] = React.useState(1)
	const [isOTPSent, setIsOTPSent] = React.useState(false)
	const [OTPEntered, setOTPEntered] = React.useState('')
	const [isSubmitting, setIsSubmitting] = React.useState(false)

	const [pageOneErrorOne, setPageOneErrorOne] = React.useState('')
	const [pageTwoErrorOne, setPageTwoErrorOne] = React.useState('')

	React.useEffect(() => {
		if (phoneNumber)
			setPhoneNumber('')
	}, [])

	// ====== Screen One Start =====
	const resetPageOneError = () => setPageOneErrorOne('')

	const handleNumber = (e) => {
		const { value, maxLength } = e.target
		const number = value.slice(0, maxLength)

		if (pageOneErrorOne)
			resetPageOneError()

		setPhoneNumber(number)
	}
	const handlePhoneSubmit = async (e) => {
		e.preventDefault()

		const token = await recaptchaRef.current.executeAsync();
		recaptchaRef.current.reset();
		if (phoneNumber.trim() === '' || phoneNumber.length < 10) {
			setPageOneErrorOne('Enter 10 digit phone number')
			return
		}
		setIsSubmitting(true)

		try {
			const { data: { code } } = await sendOtp(phoneNumber, token)
			if (code) {
				setPageNumber(pageNumber + 1)
				setIsOTPSent(true)
				setTimeout(() => setIsOTPSent(false), 5000)
			} else
				setPageOneErrorOne('Failed. Please try again')

		} catch (e) {
			setPageOneErrorOne('Failed. Please try again!')
		}
		setIsSubmitting(false)

	}

	// ====== Screen One end =====



	// ====== Screen Two Start =====
	const resetPageTwoError = () => setPageTwoErrorOne('')

	const handleChangeNumber = (e) => {
		e.preventDefault()

		setPageNumber(1) // Switch back to starting screen where the user enters phone number
	}

	const handleOTPResend = async (e) => {
		const token = await recaptchaRef.current.executeAsync();
		recaptchaRef.current.reset();
		if (pageTwoErrorOne)
			resetPageOneError()
		else if (OTPEntered)
			setOTPEntered('')

		try {
			const { data: { code } } = await resendOtp(phoneNumber, token)

			if (code) {
				setIsOTPSent(true)
				setTimeout(() => setIsOTPSent(false), 5000)
			} else {
				setPageTwoErrorOne('Error in resending OTP. Try again')
			}
		} catch (e) {
			setPageTwoErrorOne('Error in resending OTP. Try again')
		}
	}

	const handleOTPChange = (e) => {
		const { value, maxLength } = e.target
		const otp = value.slice(0, maxLength)

		if (pageTwoErrorOne)
			resetPageTwoError()

		setOTPEntered(otp)
	}

	const handleOTPVerification = async (e) => {
		e.preventDefault()

		const token = await recaptchaRef.current.executeAsync();
		recaptchaRef.current.reset();
		if (OTPEntered.trim() === '' || OTPEntered.length < 6) {
			setPageTwoErrorOne('Enter 6 digit OTP')
			return
		}

		setIsSubmitting(true)

		try {
			const { data: { code, data: jwtToken } } = await verifyOtp(phoneNumber, OTPEntered, token)

			if (code) {
				localStorage.setItem('token', jwtToken)
				setVerified(true)
				getBillsCount()
			} else {
				setPageTwoErrorOne('Enter Valid OTP')
			}
		} catch (e) {
			setPageTwoErrorOne('Enter Valid OTP')
		}

		setIsSubmitting(false)
	}
	// ====== Screen Two end =====

	return (
		<FlexBox direction="column" align="center">
			<img src={RZPBMLogo} width="200px" style={{ textAlign: 'center', margin: '64px 0px 16px' }} />
			<PageTitle>
				Hello !
				<br />
				View all your <span>digital</span> bills
			</PageTitle>
			<Process
				recaptchaRef={recaptchaRef}
				page={pageNumber}

				pageOneValueOne={phoneNumber}
				handlePageOneInputOne={handleNumber}
				onPageOneSubmit={handlePhoneSubmit}
				isPageOneSubmitting={isSubmitting}
				pageOneErrorOne={pageOneErrorOne}

				pageTwoValueOne={OTPEntered}
				pageTwoValueThree={phoneNumber}
				handlePageTwoInputOne={handleOTPChange}
				onPageTwoSubmit={handleOTPVerification}
				onPageTwoSecondarySubmit={handleChangeNumber}
				onPageTwoTertiarySubmit={handleOTPResend}
				pageTwoErrorOne={pageTwoErrorOne}
				isPageTwoSubmitting={isSubmitting}

				PageOne={PageOne}
				PageTwo={PageTwo}
				PageThree={PageOne}

				isBannerVisible={isOTPSent}
				InformationBanner={OTPSent}
			/>
		</FlexBox>
	)
}