import styled, { createGlobalStyle } from 'styled-components'
import { Color, StyleUtils } from '.'
import { space } from 'styled-system'

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: Clearface;
        src: url('fonts/ITC-Clearface-Std.ttf') format('truetype');
        font-weight: 400;
    }

    @font-face {
        font-family: 'Clearface bold';
        src: url('fonts/clearfacestd-bold.otf') format('truetype');
        font-weight: 800;
    }

    html, body{
        margin: 0;
        padding: 0;
        font-size: 12px;
        height: 100%;

        div.brand-carousel{
            display: flex;
            justify-content: center;
        }

        ${StyleUtils.breakpoints('lg')`
            font-size: 16px;
        `}
           
        *{
            font-family: 'Roboto';
        }

        .bills-pagination{
            display: flex;
            align-items: center;
            list-style: none;
            width: 60%;
            justify-content: space-around;
            padding: 0px;
            margin: 1em auto;

            .bills-pagination--page{
                padding: 5px 10px;
                background-color: #fff;
                border-radius: 4px;
            }

            .bills-pagination--page__active{
                background-color: #B0CEFF;
            }
        }
    }
`

export const PageTitle = styled.h1`
    font-size: 2em;
    text-align: center;
    font-family: 'Clearface bold', serif;
    font-weight: 800;
    color: ${Color.text};

    ${space};

    ${StyleUtils.breakpoints('md')`
        font-size: 3em;
    `}

    span{
        color: ${Color.base};
        font-family: 'Clearface bold', serif;
        font-weight: 800;
    }
`
