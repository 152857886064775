import styled from "styled-components";
import { FlexBox } from "../../styles/styleme";
import { Color } from "../../styles";
import { PageTitle } from "../../styles/element";

export const MainContainer = styled.div`
    height: 100%;
	overflow: scroll;
`

export const StyledBills = styled(FlexBox)`
    height: 100vh;
    max-width: 600px;
    margin: 0px auto;
    overflow: hidden;
`

export const ActionButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        margin-right: 10px;
    }
    span{
        vertical-align: top;
    }
`

export const StyledBillsFooter = styled.div`
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    text-align: center;
    padding: ${props => props.bg ? '0px' : '16px 5px'};
    background-color: ${props => props.bg || Color.monochrome.light};
    color: ${props => props.bg ? '#fff' : '#000'};
    position: fixed;
    bottom: 0px;

    ${ActionButton}{
        width: 100%;
        height: 100%;
        padding: 16px;
        border: none;
        background-color: transparent;
        color: #fff;
    }
`

export const ActionClose = styled.button.attrs(() => ({
	type: 'button'
}))`
    position: absolute;
    padding: 16px 0px;
    top: 0px;
    right: 16px;
    border: none;
    background-color: transparent;
    width: 15px;
`

export const PhoneInput = styled(FlexBox)`
    border: 1px solid #CCCCCC;
    margin: 5px 0px 20px;
    border-radius: 6px;

    div{
        font-size: 14px;
        padding: 5px 10px;
        border: 1px solid transparent;
        border-right: 1px solid #E1E8ED;
    }

    &&&{
        input{
            border-radius: 0px;
            margin: 0px;
            border: none;
        }
    }
`

export const ButtonLink = styled.button.attrs(() => ({
	type: 'button'
}))`
   border: none;
   background-color: transparent;
   color: ${Color.base};
   margin-bottom: 10px;
`


export const StyledBillRow = styled(FlexBox)`
    background-image: linear-gradient(to right, #dadada 70%, rgba(255, 255, 255, 0) 20%);
    background-position: bottom;
    background-size: 13px 1px;
    background-repeat: repeat-x;
    text-decoration: none;
    color: #000;

    img{
        width: 30px;
        height: 30px;
        background-color: #eee;
        border-radius: 50%;
        border: 1px solid #eee;
    }

    input{
        border: 1px solid #ccc;
        background-color: transparent;
    }

    input, img{
        margin-right: 10px;
    }

    p{
        margin: 0;
    }
    
`

export const Menu = styled.div`
    width: 100px;
    border: 1px solid #DADADA;
    position: absolute;
    top: 35px;
    right: 25px;
    background-color: #fff;
    border-radius: 4px;
    opacity: ${props => props.opacity};
    height: ${props => props.height};
    transition: all 0.2s;
    overflow: hidden;


    li{
        margin: 0;

        &:first-child, &:nth-child(2){
            border-bottom: 1px solid #DADADA;
        }
    }

    button{
        width: 100%;
        padding: 10px 0px;
        border: none;
        background-color: transparent;
    }
`

export const MainHeading = styled(PageTitle)`
    &&&{
        font-size: 1.4em;
        max-width: 400px;
        margin: 0.67em auto;
    }
`

export const FooterButton = styled.button`
    font-size: 10px;
    border: none;
    background-color: transparent;
    padding: 0px 10px;

    &:first-child, &:nth-child(2){
        border-right: 1px solid #DADADA;
    }
`

export const StyledDeleteConfirmationModal = styled.div`
    background-color: #fff;
    width: 70%;
    max-width: 280px;
    color: #000;
    border-radius: 6px;
    padding-top: 5px;
    margin-top: 200px;

    h4{
        text-align: center;
    }

    p{
        width: 80%;
        text-align: center;
        margin: 1em auto;
    }
`

export const ActionArea = styled.div`
    padding: 15px 0px;
    border-top: 1px solid #DADADA;

    button{
        background-color: transparent;
        border: none;
        color: ${Color.primary.default};
        width: 50%;

        &:first-child{
            color: red;
            border-right: 1px solid #DADADA;
        }
    }
`

export const StyledDT = styled.div`
    width: inherit;
    max-width: inherit;
    height: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    overflow: auto;
    transform: ${props => `translateX(${props.translateX})`};
    opacity: ${props => props.opacity};
    transition: all 0.3s;
`

export const DTNavbar = styled.div`
    padding: 10px;
    box-shadow: 0px 4px 10px 0px #eee;

    button{
        background: transparent;
        border: none;
        margin-left: 10px;

        *{
            vertical-align: middle;
        }

        img{
            margin-right: 10px; 
        }
    }
`

export const DTHeroSection = styled(FlexBox)`
    margin-top: 20px;

    & > div{
        width: 80%;
        text-align: center;
    }

    img{
        width: 80px;
        margin: 20px 0px;
    }
`