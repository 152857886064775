// FIXME: This is temp Toast and import Toast when design system is ready

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { spacing, color, borderRadius } from '../styles'
import { FlexBox } from './styleme'

const ToastType = {
    success: '#28C67A',
    warning: '#FBEECA',
    error: '#D76565',
}

const StyledToast = styled(FlexBox)`
    background-color: transparent;
    position: fixed;
    bottom: 40px;
    opacity: 0;
    transform: translateY(40px);
    height: 0px;
    transition: all 230ms;

    ${props => props.isOpen && css`
        opacity: 1;
        transform: translateY(0px);
        height: auto;
    `}

    div{
        background-color: #ccc;
        padding: 8px 16px;
        border-radius: 4px;
        font-size: 0.9em;
        min-width: 180px;
        text-align: center;

        ${props => props.type && css`
            background-color: ${ToastType[props.type]};
        `}
    }
`

/* 

========= ******** =========

    Not using function component here as you cannot attach ref to function component
    functions and thats what we need here
    
    Read: -
    https://blog.logrocket.com/cleaning-up-the-dom-with-forwardref-in-react/#:~:text=Attaching%20refs,they%20don't%20have%20instances.

========= ******** =========

*/

let timeoutId
const defaultHideDuration = 3000

class Toast extends Component{
    constructor(){
        super()

        this.state = {
            isOpen: false
        }
    }

    componentDidUpdate(){
        if(this.state.isOpen === true)
            this.initiateTimer()
    }
    
    initiateTimer(){
        timeoutId = setTimeout(() => {
            this.setState({ isOpen: false })
            timeoutId = ''
        }, this.props.autoHideDuration || defaultHideDuration)
    }
    
    open(){        
        // Check and clear if previous id is present as this is a new timer call
        if(timeoutId)
            clearTimeout(timeoutId)

        this.setState({ isOpen: true })
    }

    close(){
        this.setState({ isOpen: false })
        clearTimeout(timeoutId)
    }
    
    render(){
        return(
            <StyledToast
                justify="center" 
                isOpen={this.state.isOpen} 
                type={this.props.type}
                expand
            >
                <div>
                    {this.props.message}
                </div>
            </StyledToast>
        )
    }
}

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    autoHideDuration: PropTypes.number,
    type: PropTypes.string,
}

export default Toast