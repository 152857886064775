import React from 'react'
import { Modal } from "./Contact"
import styled from "styled-components"
import { StyleUtils } from "../../styles"

const CloseButton = styled.button`
    /* outline: none; */
    padding: 10px;
    background: none;
    position: absolute;
    top: calc(5em - 25px);
    border: none;
    right: 10px;

    img{
        width: 25px;
        transform: rotate(45deg);
    }

    ${StyleUtils.hideOnComputer};
`

const StyledDisclaimer = styled.div`
    ${StyleUtils.breakpoints('mobile')`
        padding: 5em 2em;
        position: relative;
        top: 30px;
        width: 100%;
    `};

    background-color: #fff;
    border-radius: 10px;
    padding: 1em 2em;
    color: #000;
    width: 50%;
    max-width: 500px;
    box-sizing: border-box;
    max-height: 500px;
    overflow: auto;

    ul{
        max-height: 400px;
    }
`

const Header = styled.h3`
    text-decoration: underline;
    text-align: center;
`

const ListHeader = styled.li`
    text-decoration: underline;
    font-weight: bold;
`

const Disclaimer = ({ onCrossClick }) => {
	return (
		<Modal onCrossClick={onCrossClick}>
			<StyledDisclaimer>
				<CloseButton onClick={onCrossClick} hideOnComputer>
					<img src="add.svg" />
				</CloseButton>
				<Header>Disclaimer</Header>
				<ul>
					<li>JHKP TECHNOLOGIES PVT LTD does not stake claim to third party company names, trademarks™ or registered® trademarks displayed on the website neither claim any association with these companies. Their brand logos are used for representational purposes only</li>
					<li>BillMe™ is a registered trademark of JHKP TECHNOLOGIES PVT. LTD.</li>
				</ul>
			</StyledDisclaimer>
		</Modal>
	)
}

const TOS = ({ onCrossClick }) => {
	return (
		<Modal onCrossClick={onCrossClick}>
			<StyledDisclaimer>
				<CloseButton onClick={onCrossClick} hideOnComputer>
					<img src="add.svg" />
				</CloseButton>
				<Header>Terms of Use</Header>
				<>
					<p style={{ textAlign: "justify" }}>
						This document/agreement/understanding is a computer-generated electronic
						record published in terms of Rule 3 of the Information Technology
						(Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 (amended
						from time to time) read with Information Technology Act, 2000 (amended from
						time to time) and does not require any physical or digital signatures.
					</p>
					<p style={{ textAlign: "justify" }}>
						JHKP Technologies Private Limited (“BillMe”) provides its proprietary
						software which shares digital invoices or digital bills to Customers (who
						may be referred to as “You/Your”) who have purchased goods or services via
						the Merchants’ retail store and/or e-commerce platform (the "Service(s)").
						These terms of use (“Terms of Use") govern Your use of the Services provided
						by BillMe.
					</p>
					<p style={{ textAlign: "justify" }}>
						By using the Services and/or the website (https://billme.io/) ("Website”),
						You shall be presumed to have accepted and read these Terms of Use, along
						with the Privacy Policy, and any other policies as updated on our website
						from time to time.
					</p>
					<p style={{ textAlign: "justify" }}>
						Furthermore, You unconditionally and irrevocably accept the terms set out in
						these Terms. These Terms of Use together with the Policies (the “Terms”),
						constitute a binding and enforceable agreement between You and BillMe.
					</p>
					<p style={{ textAlign: "justify" }}>
						<strong>Definitions</strong>
					</p>
					<p>
						<ul>
							<li>
								“<strong>Content(s)</strong>” means any information shared with the
								Customers by BillMe in the course of providing the Services, including,
								but not limited to, text, images, graphics, and other types of data.
							</li>
							<li>
								<strong>“Customer”</strong> is a person or an entity that receives,
								consumes, or buys a product or service from the Merchant.
							</li>
							<li>
								<strong>“Customer Data”</strong> is any information provided by You, which
								includes but is not limited to Your Contact number, email id, complete or
								partial address, full name, age, age range, date of birth and gender, and
								any additions and/or modifications made thereto by You.
							</li>
							<li>
								“<strong>Intellectual Property Rights</strong>” means copyright, patent
								right, utility model right, trademark right, design right and any other
								intellectual property right, including the right to obtain and register
								any such right.
							</li>
							<li>
								<strong>“Invoice”</strong> or <strong>“Bill”</strong> is a legal proof of
								sale sent by the Merchant to You in a physical paper form or digital form.
							</li>
							<li>
								<strong>“Merchant”</strong> is a business owner that uses BillMe’s
								services to send digital Bills or invoices to their end users/Customers.
							</li>
						</ul>
					</p>
					<div style={{ display: "inline-block", position: "inline-block" }}>
						<p style={{ textAlign: "justify" }}>
							<strong>Ownership and Grant of Rights</strong>
						</p>
						<p>
							<ul>
								<li>
									You acknowledge and agree that&nbsp; BillMe, or the relevant Merchant of
									BillMe, owns all rights, title and interest in and to the Services,
									including any Intellectual Property Rights which subsist in the Services
									(whether registered or not). You further acknowledge that the Services may
									contain information which is designated confidential by BillMe and that
									You shall not disclose such information without BillMe’s prior written
									consent.
								</li>
								<li>
									BillMe grants You a personal, non-exclusive, non-transferable, limited
									privilege to avail the Services and make personal use of the Services.
									This license does not confer any right to download, copy, create a
									derivative work, modify, reverse engineer, reverse assemble or otherwise
									attempt to discover any source code, sell, assign, sub-license, grant a
									security interest in or otherwise transfer any right in the Services. You
									do not have the right to use any of BillMe or its Merchants’ trade names,
									trademarks, service marks, logos, and other distinctive brand features.
								</li>
								<li>
									Any and all Intellectual Property Rights pertaining to the information
									(including, but not limited to information on Invoices) transmitted or
									posted by Customers through the Services shall not belong to BillMe, and
									the Customer grants BillMe a limited right to use, store and process such
									information for the purposes of receiving the Services.
								</li>
								<li>
									Notwithstanding the preceding paragraph, each Customer shall grant BillMe
									a worldwide, non-exclusive, free, and transferable license with the right
									to sublicense for the use, reproduction, distribution, creation of
									derivative work, display and execution of any of the Intellectual Property
									Rights set forth in the preceding paragraph. Such license granted in
									accordance with the preceding provision shall remain effective even after
									the relevant Customer stops using the Services and/or the Customer’s
									Account is deleted. Each Customer shall agree that he/she will not
									exercise his/her moral rights against BillMe or any successor or licensee
									of BillMe.
								</li>
								<li>
									Customers shall agree that BillMe, in its sole discretion, reserves the
									right to make changes or additions to all or part of the Services without
									giving prior notice to Customers, including changes and additions to the
									functions, pricing and the fee-based services, if any.
								</li>
							</ul>
						</p>
					</div>
					<div style={{ display: "inline-block", position: "relative" }}>
						<p style={{ textAlign: "justify" }}>
							<strong>Use of the Services</strong>
						</p>
						<p>
							<ul>
								<li>
									You agree not to use the Services for any purpose that is unlawful,
									illegal, or forbidden by these Terms, or any local laws that might apply
									to You. You shall agree to comply with laws that apply in India. We may,
									at our sole discretion, at any time and without advance notice or
									liability, suspend, terminate, or restrict Your access to all or any
									component of the Services.
								</li>
								<li>
									By making use of the Services, and furnishing Your contact details, You
									hereby agree that You are interested in knowing more or availing and/or
									purchasing various products, services that BillMe or any other third party
									may offer to /provide to /share with /send to You from time to time
									through any means including but not limited to telephone, SMS (short
									messaging service), electronic mail (e-mail), whatsapp or any other
									messaging service/mobile application or any other physical, electronic or
									digital means/mode. You hereby agree that BillMe may contact You either
									electronically or through phone, to understand Your interest in the
									selected products and Services and to fulfil Your demand. Further, You
									also expressly agree and authorize BillMe, its partners, service
									providers, vendors and other third parties to contact You for the purpose
									of (i) offering or inviting Your interest in availing other products or
									services offered by third parties, or (ii) for sending other marketing
									campaigns, offers, or any other information through other means including
									but not limited to telephone, SMS (short messaging service), electronic
									mail (e-mail), Whatsapp or any other messaging service/mobile application
									or any other physical, electronic or digital means/mode.
								</li>
								<li>
									By using the Services, You hereby consent to the use of Your information
									as we have outlined in our Privacy Policy.
								</li>
								<li>
									You specifically understand and agree that by using the Services You
									authorize BillMe, its affiliates, partners and third parties to contact
									You for any follow up calls in relation to the Services and/ or for
									offering or inviting Your interest in availing any other product or
									service offered by BillMe or such third parties. You agree and consent to
									receive communications relating to all of the above on Your phone/mobile
									number (if any provided) by You and expressly waive any registration or
									preference made under DND/NCPR list under the applicable Telecom
									Regulatory Authority of India (TRAI) regulations.
								</li>
								<li>
									You shall use the Services only in a manner specified by BillMe for the
									purposes set forth in these Terms and as long as the provisions hereof are
									complied with.
								</li>
								<li>
									You shall use the Services “as is” and shall not be permitted to
									replicate, change, adjust or otherwise modify the same.
								</li>
								<li>
									For information regarding any other web pages linked to the Invoices,
									please refer to the terms of use provided on the applicable third party
									websites.
								</li>
								<li>
									BillMe or its Merchants may, from time to time, send or display
									advertisements, promotions and/or monthly reports on usage to Customers’
									registered email addresses.
								</li>
								<li>You shall not:</li>
								<p>
									<ul>
										<li>
											describe Yourself as an agent or representative of BillMe or its
											Merchants;
										</li>
										<li>
											represent that You have any rights to offer any products or services
											offered by BillMe or its Merchants;
										</li>
										<li>
											make any representations to Your customer or any third party or give any
											warranties which may require BillMe or its Merchants to undertake to or
											be liable for, whether directly or indirectly, any obligation and/or
											responsibility to Your customer or any third party;
										</li>
										<li>
											use the Services to facilitate any activity which is unlawful, illegal,
											unauthorised, is carried on with the intent to defraud, or is likely to
											result in Your unjust enrichment and/or unlawful gain;
										</li>
										<li>
											perform any acts that are or are likely to be interfering with the
											Intellectual Property Right, publicity right, privacy, reputation, or
											any other right or benefit of BillMe, or a third party;
										</li>
										<li>
											perform any acts to falsely assume the identity of BillMe or a third
											party including other Customers;
										</li>
										<li>perform any acts not in compliance with these Terms;</li>
										<li>perform any other acts, as may be deemed inappropriate by BillMe.</li>
									</ul>
								</p>
							</ul>
						</p>
					</div>
					<div style={{ display: "inline-block", position: "relative" }}>
						<div style={{ display: "inline-block", position: "relative" }}>
							<p>
								<ul>
									<li>
										You shall not host, display, upload, modify, publish, transport, store,
										update or share any content on the website that:
									</li>
									<ul>
										<li>
											belongs to another person and to which the user does not have any right;
										</li>
										<li>
											is defamatory, obscene, pornographic, paedophilic, invasive of another‘s
											privacy, including bodily privacy, insulting, or harassing on the basis
											of gender, libellous, racially or ethnically objectionable, relating or
											encouraging money laundering or gambling, or otherwise inconsistent with
											or contrary to the laws in force;
										</li>
										<li>is harmful to child;</li>
										<li>
											infringes any patent, trademark, copyright, or other proprietary rights;
										</li>
										<li>violates any law for the time being in force;</li>
										<li>
											deceives or misleads the addressee about the origin of the message or
											knowingly and intentionally communicates any information which is
											patently false or misleading in nature but may reasonably be perceived
											as a fact;
										</li>
										<li>impersonates another person;</li>
										<li>
											threatens the unity, integrity, defence, security or sovereignty of
											India, friendly relations with foreign States, or public order, or
											causes incitement to the commission of any cognisable offence or
											prevents investigation of any offence or is insulting other nation;
										</li>
										<li>
											contains software virus or any other computer code, file or program
											designed to interrupt, destroy or limit the functionality of any
											computer resource; and
										</li>
										<li>
											is patently false and untrue, and is written or published in any form,
											with the intent to mislead or harass a person, entity or agency for
											financial gain or to cause any injury to any person.
										</li>
									</ul>
								</ul>
							</p>
						</div>
						<div style={{ display: "inline-block", position: "relative" }}>
							<p style={{ textAlign: "justify" }}>
								<strong>Suspension or Termination of the Services</strong>
							</p>
							<p>
								<ul>
									<li>
										BillMe shall have the right to immediately terminate Services at its
										discretion.
									</li>
									<li>
										In the event of any of the following, BillMe shall have the right to
										temporarily suspend all or part of the Services without giving prior
										notice to Customers:
									</li>
									<ul>
										<li>
											An emergency or periodical maintenance or repair work is performed on
											the hardware, software or other network devices for the Services;
										</li>
										<li>
											There has been a system overload due to increased usage or other
											unexpected reason;
										</li>
										<li>There is a need for a security measure for Customers;</li>
										<li>Telecommunications provider does not provide its services;</li>
										<li>
											Provision of the Services is rendered difficult due to a force majeure
											event;
										</li>
										<li>
											Provision of the Services is rendered difficult due to laws or
											regulations or due to any dispositions made in accordance with such laws
											or regulations; or
										</li>
										<li>
											BillMe deems it necessary to suspend the Services for a reason
											equivalent to any of the preceding items.
										</li>
									</ul>
									<li>
										The Company shall not be in any way responsible for any loss or damage
										incurred by Customers as a result of BillMe’s actions under this clause.
									</li>
								</ul>
							</p>
						</div>

						<p style={{ textAlign: "justify" }}>
							<strong>Disclaimer of Warranty and Limitation of Liability</strong>
						</p>
						<p>
							<ul>
								<li>
									BillMe warrants that the Service(s) will perform in all material respects
									in accordance with these Terms. To the maximum extent permitted by
									applicable laws and except as stated in these Terms, the Services are
									provided on an “as is” basis. You acknowledge that BillMe does not warrant
									that the Service(s)s will be uninterrupted or error free or fit for Your
									specific business purposes and that the Services are free of any defects
									or malfunctions.
								</li>
								<li>
									BillMe (including its officers, directors, employees, representatives,
									affiliates, and providers) will not be in any way responsible or liable
									for any loss or damage incurred by any Customer in relation to the
									Services, including but not limited to (a) any injury, death, loss, claim,
									act of God, accident, delay, or any direct, special, exemplary, punitive,
									indirect, incidental or consequential damages of any kind (including
									without limitation lost profits or lost savings), whether based in
									contract, tort, strict liability or otherwise, that arise out of or is in
									any way connected with (i) any failure or delay (including without
									limitation the use of or inability to use any component of the Services),
									or (ii) any use of the Services or content, or (iii) the performance or
									non-performance by BillMe or its Merchants, even if we have been advised
									of the possibility of damages to such parties or any other party, or (iv)
									any damages to or viruses that may infect Your computer equipment or other
									property as the result of Your access to the Services. The Invoices may
									provide links to other third-party websites. However, since BillMe has no
									control over such third-party websites, You acknowledge and agree that
									BillMe is not responsible for the availability of such third-party
									websites and does not endorse and is not responsible or liable for any
									content, advertising, products or other materials on or available from
									such third-party websites. You further acknowledge and agree that BillMe
									shall not be responsible or liable, directly or indirectly, for any damage
									or loss caused or alleged to be caused by or in connection with use of or
									reliance on any such content, goods or services available on or through
									any such third-party websites. Such third-party websites may have separate
									terms and conditions and privacy policy, and which are independent of
									BillMe and therefore, we advise You to read the terms of use and
									conditions available on such websites before You access any such third-
									party website.
								</li>
								<li>
									You agree to indemnify and hold BillMe (and its officers, affiliates,
									group company, directors, agents and employees) harmless from any and
									against all claims, whether or not brought by third parties, causes of
									action, demands, recoveries, losses, damages, fines, penalties or other
									costs or expenses of any kind or nature, including reasonable attorneys'
									fees, or arising out of or related to Your breach of these Terms, Your
									violation of any applicable laws or the rights of a third party, or Your
									use of the Services or any disputes between You and any third party.
								</li>
							</ul>
						</p>
					</div>
					<div style={{ display: "inline-block", position: "relative" }}>
						<p style={{ textAlign: "justify" }}>
							<strong>Notices</strong>
						</p>
						<p>
							<ul>
								<li>
									Any inquiry regarding the Services and any other communications from
									Customers to BillMe shall be made in writing using the contact information
									available on the BillMe website.
								</li>
							</ul>
						</p>
						<p style={{ textAlign: "justify" }}>
							<strong>Assignment</strong>
						</p>
						<p style={{ textAlign: "justify" }}>
							Customers hereby agree that BillMe may transfer to its affiliates its title,
							rights and obligations towards Customer Data and any other data.
						</p>
						<p style={{ textAlign: "justify" }}>
							<strong>Severability</strong>
						</p>
						<p style={{ textAlign: "justify" }}>
							If any of the provisions of these Terms are deemed as invalid or
							unenforceable under any applicable consumer protection laws or any other
							laws or regulations, the remainder of these Terms shall continue in full
							force and effect.
						</p>
						<p style={{ textAlign: "justify" }}>
							<strong>Waiver</strong>
						</p>
						<p style={{ textAlign: "justify" }}>
							Any failure or delay by BillMe to enforce or exercise any provision of these
							Terms, or any related right, shall not constitute a waiver by BillMe of that
							provision or right. The exercise of one or more of BillMe’s rights hereunder
							shall not be a waiver of, or preclude the exercise of, any rights or
							remedies available to BillMe under these Terms or in law or at equity. Any
							waiver of any provision shall only be effective if made in writing and
							executed by a duly authorized officer of BillMe.
						</p>
						<p style={{ textAlign: "justify" }}>
							<strong>Dispute Resolution, Governing Law and Jurisdiction</strong>
						</p>
						<p>
							<ul>
								<li>
									These Terms shall be governed by the laws of India, and any disputes
									arising out of or in connection with these Terms shall be subject to the
									exclusive jurisdiction of the courts in Bangalore.
								</li>
								<li>
									In the event of any discrepancy or inconsistency between the English and
									other languages versions of these Terms, the English version shall
									prevail.
								</li>
								<li>
									In the event of any dispute arising between a Customer and a Merchant or a
									third party, the Customer shall immediately resolve the matter at his/her
									own cost and responsibility with the Merchant, and BillMe shall not be in
									any way involved or responsible therefor.
								</li>
							</ul>
						</p>
					</div>
					<p style={{ textAlign: "justify" }}>
						<strong>PRIVACY</strong>
					</p>
					<p style={{ textAlign: "justify" }}>
						YOUR PRIVACY IS EXTREMELY IMPORTANT TO US. UPON ACCEPTANCE OF THESE TERMS OF
						USE YOU CONFIRM THAT YOU HAVE READ, UNDERSTOOD AND UNEQUIVOCALLY ACCEPTED
						OUR POLICIES, INCLUDING THE PROVISIONS OF OUR PRIVACY POLICY.
					</p>
					<p style={{ textAlign: "justify" }}>
						These Terms of use were last updated on 4<sup>th</sup> August 2023.
					</p>
				</>
			</StyledDisclaimer>
		</Modal>
	)
}

const Privacy = ({ onCrossClick }) => {
	return (
		<Modal onCrossClick={onCrossClick}>
			<StyledDisclaimer>
				<CloseButton onClick={onCrossClick} hideOnComputer>
					<img src="add.svg" />
				</CloseButton>
				<Header>Privacy Policy</Header>
				<>
					<p>
						JHKP Technologies Private Limited(“BillMe” ) provides its proprietary
						software which shares digital invoices or digital bills to customers (who
						may be referred to as “ You/Your” ) who have purchased goods or services via
						the Merchants’ retail store and/or e-commerce platform (the "Service(s)").
					</p>
					<p>
						This privacy policy describes how BillMe collects and processes the
						information You provide in connection with Your use of the Services. It also
						describes the choices available to You regarding our use of Your personal
						information and how You can access and update this information.
					</p>
					<h2>Definitions</h2>
					<ul>
						<li>
							“Merchant” is a business owner that uses BillMe’s services to send digital
							Bills or invoices to their end users/Customers.
						</li>
						<li>
							A “Customer” is a person or an entity that receives, consumes, or buys a
							product or service from the Merchant.
						</li>
						<li>
							An “Invoice” or “Bill” is a legal, tax compliant proof of sale sent by the
							Merchant to You in a physical paper form or digital form.
						</li>
						<li>
							“Customer Personal Data” includes but is not limited to Your contact
							number, email id, complete or partial address, full name, age, age range,
							date of birth and gender.
						</li>
					</ul>
					<p>
						<span style={{ textDecoration: "underline" }}>
							Information the app collects from Merchants.
						</span>
					</p>
					<ul>
						<li>
							Following information pertaining to the Customer is collected from
							Merchants in the course of the Service -
						</li>
						<ul>
							<li>
								Invoice data (all data present in their Invoice such as products
								purchased, store details, products purchased, quantities purchased, mode
								of payment, taxes paid, discounts, rewards/coupon information, date of
								purchase, location of purchase, etc.),
							</li>
							<li>number of transactions,</li>
							<li>type of invoice issued (digital or physical),</li>
							<li>Customer contact number,</li>
							<li>Customer email,</li>
							<li>
								other Customer personal data (if Customer voluntarily gives it to
								theMerchant at the cash counter and/or it is displayed in invoice
								data)&nbsp;
							</li>
						</ul>
					</ul>
					<p>
						<span style={{ textDecoration: "underline" }}>
							Information BillMe collects from Customers.
						</span>
					</p>
					<p>
						<ul>
							<li>
								In addition to the above, following information may be collected from
								Customers who receive digital Invoice from Merchants via retail store/or
								e- commerce platform;
							</li>

							<ul>
								<li>other personal data such as the Customer’s -</li>
								<ul>
									<li>First name</li>
									<li>Last name</li>
									<li>Date of birth&nbsp;</li>
									<li>Gender</li>
									<li>State</li>
									<li>City</li>
									<li>Complete address</li>
									<li>Marital status</li>
									<li>Anniversary date</li>
									<li>Spouse’s name</li>
									<li>Profession</li>
									<li>Employing company’s name</li>
									<li>Browser used</li>
									<li>Opinions/feedback shared</li>
								</ul>
								<li>Invoice data,</li>
								<li>IP address,</li>
								<li>Model of phone used.</li>
							</ul>

						</ul>
					</p>
					<p>
						All Customer data is only collected when the Customer voluntarily gives
						their details to the Merchant for the purpose of digital invoicing at the
						cash counter of the retail store and/or through a purchase made on the e-
						commerce website of the Merchant.
					</p>
					<ul>
						<li>
							Information about transactions made. We do not capture or store any kind
							of payment information including Your credit or debit card number, card
							expiration date, CVV, and billing address, in order to make purchases on
							the Merchants’ retail store and/or e-commerce platform.
						</li>
						<li>
							If You email or contact us, the content of Your message, Your email
							address, and Your contact information, would be stored by us to respond to
							Your request.
						</li>
					</ul>
					<p>
						<span style={{ textDecoration: "underline" }}>
							How Do We Use Your Personal Information?
						</span>
					</p>
					<p>
						We use the personal information we collect from the Customer to deliver a
						digital invoice from the Merchant You have shopped from. All Customer data
						collected from the Merchant using BillMe Services is stored on the platform
						only for digital invoicing and to provide the Merchants’ customer
						relationship management services (If Customer has allowed Merchants to
						store/use Customers personal information.)
					</p>
					<p>
						<span style={{ textDecoration: "underline" }}>
							Sharing Your Personal Information
						</span>
					</p>
					<p>
						We do not share/sell any of Your personal data to any third party. Data may
						be shared to comply with applicable laws and regulations, to respond to an
						order of a judicial/government authority, search warrant or other lawful
						request for information we receive, or to otherwise protect our rights. We
						may use this information for internal analysis and to provide you with
						location-based services, such as advertising and other personalised content,
						and incrementally offer services of our affiliates to You.
					</p>
					<p>
						<span style={{ textDecoration: "underline" }}>
							Access to information (Customers)
						</span>
					</p>
					<p>
						Customers have the right to access personal information we hold about them
						and to ask that their personal information be corrected, updated, or
						deleted. If one
					</p>
					<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>
					<p>
						would like to exercise this right, please contact us through email
						support@billme.co.in or view Your data on user.billme.co.in.
					</p>
					<p>
						<span style={{ textDecoration: "underline" }}>Data Retention</span>
					</p>
					<p>
						When You place an order through a Merchant’s retail store and/or e-commerce
						platform, we will maintain Your order information for our records unless You
						ask us to delete this information. We may update this privacy policy from
						time to time in order to reflect, for example, changes to our practices or
						for other operational, legal or regulatory reasons.
					</p>
					<p>
						Contact us for more information about our privacy practices, if You have
						questions, or if You would like to make a complaint, please contact us by
						email at{" "}
						<a href="mailto:Support@billme.co.in" target="_blank" rel="noopener">
							Support@billme.co.in
						</a>
						.
					</p>
					<p>
						<span style={{ textDecoration: "underline" }}>Third-Party Services</span>
					</p>
					<p>
						Our Platform may contain links to other websites. Please note that when You
						click on one of these links, You are entering another website over which we
						have no control and for which we will bear no responsibility. Often these
						websites require You to enter Your personal information. We encourage You to
						read the privacy policies of all such websites as their policies may differ
						from ours. You agree that we shall not be liable for any breach of Your
						privacy of personal information or loss incurred by Your use of such
						websites or services.
					</p>
					<p>
						<span style={{ textDecoration: "underline" }}>Transfer of Information</span>
					</p>
					<p>
						In the event that we are involved in a bankruptcy, merger, acquisition,
						reorganization, or sale of assets, Your personal data may be transferred as
						part of that transaction. This Privacy Policy will apply to Your personal
						data as transferred to the new entity. We may also disclose personal data
						about You to our corporate affiliates in order to help operate our services
						and our affiliates’ services.
					</p>
					<p>
						<span style={{ textDecoration: "underline" }}>Security</span>
					</p>
					<p>
						We follow accepted industry standards to protect the personal information
						submitted to us, both during transmission and once we receive it. However,
						no method of transmission over the Internet, or method of electronic
						storage, is 100% secure, therefore, we cannot guarantee absolute security.
					</p>
					<p>For more information about security on our Website, <a href="https://assets.billme.co.in/legal/BillMeSecurityDeck.pdf">please click here.</a></p>
					<p>
						<span style={{ textDecoration: "underline" }}>Contact Details</span>
					</p>
					<p>
						If You have any questions about this Privacy Policy, please contact us by
						email; Name: JHKP TECHNOLOGIES PVT LTD
					</p>
					<p>Email Id: info@billme.co.in</p>
					<p>Website URL: billme.co.in</p>
				</>
			</StyledDisclaimer>
		</Modal>
	)
}

export { Disclaimer, Privacy, TOS }