import React from 'react'
import { Color } from "../../styles"
import { Login } from "./Login"
import { Main } from "./Main"
import { StyledBills, StyledBillsFooter, ActionButton, FooterButton, StyledDeleteConfirmationModal, ActionArea, ActionClose } from "./styles"
import { listBills, deleteBills, billsCount } from "../../api/actions"
import { FlexBox } from "../../styles/styleme"
import { getTokenHeader } from "../../utils/getConfig"
import { Modal } from "../common/Contact"
import { DataTransparency } from "./DataTransparency"
import Toast from "../../styles/Toast"

const ACTION_TYPE = {
	DELETE: 'delete',
	SHARE: 'share'
}

const FOOTER_BUTTON = {
	PRIVACY: 'privacy',
	DISCLAIMER: 'disclaimer',
	TRANSPARENCY: 'transparency',
	TOS: 'terms of use',
}

const BillsFooter = ({
	actionType,
	selectedBillCount,
	handleActionClick,
	handleFooterClick,
	resetActionType,
}) => {
	let bgColor = ''
	const isDelete = actionType === ACTION_TYPE.DELETE
	const isShare = actionType === ACTION_TYPE.SHARE

	if (isDelete)
		bgColor = "#D76565"
	else if (isShare)
		bgColor = Color.primary.default

	const getFooter = () => {
		if (isDelete) {
			return (
				<ActionButton onClick={() => handleActionClick('delete')}>
					<img src="trash.svg" />
					<span>Delete bills ({selectedBillCount})</span>
				</ActionButton>
			)
		} else if (isShare) {
			return (
				<ActionButton onClick={() => handleActionClick()}>
					<img src="share.svg" />
					<span>Share bills ({selectedBillCount})</span>
				</ActionButton>
			)
		} else {
			return (
				<FlexBox justify="center">
					<FooterButton type="button" onClick={handleFooterClick('privacy')}>
						Privacy Policy
					</FooterButton>
					<FooterButton type="button" onClick={handleFooterClick('transparency')}>
						Data Transparency
					</FooterButton>
					<FooterButton type="button" onClick={handleFooterClick('disclaimer')}>
						Disclaimer
					</FooterButton>
					<FooterButton type="button" onClick={handleFooterClick('terms of use')}>
						Terms of use
					</FooterButton>
					<FooterButton type="button" onClick={() => { window.location.href = 'https://assets.billme.co.in/legal/BillMeGrievanceRedressal.pdf' }}>
						Grievances
					</FooterButton>
				</FlexBox>
			)
		}
	}

	return (
		<StyledBillsFooter bg={bgColor}>
			{getFooter()}
			{
				isDelete &&
				<ActionClose onClick={resetActionType}>
					<img src="close-white.svg" alt="Close delete action" width="100%" />
				</ActionClose>
			}
		</StyledBillsFooter>
	)
}

export const BillsPage = (props) => {
	const [isLoggedin, setIsLoggedIn] = React.useState(false)
	const [isCheckingJWT, setIsCheckingJWT] = React.useState(false)
	const [phoneNumber, setPhoneNumber] = React.useState('')
	const [bills, setBills] = React.useState()
	const [isBillLoading, setIsBillLoading] = React.useState(false)
	const [count, setCount] = React.useState(0)

	const [actionType, setActionType] = React.useState('')
	const [selectedBills, setSelectedBills] = React.useState([])

	const [isActionModalVisible, setIsActionModalVisible] = React.useState(false)
	const [isDTVisible, setIsDTVisible] = React.useState(false)

	const logoutRef = React.useRef(null)
	const deleteRef = React.useRef(null)
	const deleteBillsErrorRef = React.useRef(null)

	React.useEffect(() => {
		handleJWTCheck()
	}, [])

	const handleBillSelect = value => _ => {
		const copySelectedBills = [...selectedBills]

		if (copySelectedBills.includes(value)) {
			const elementAt = copySelectedBills.indexOf(value)
			copySelectedBills.splice(elementAt, 1)
		} else
			copySelectedBills.push(value)

		setSelectedBills(copySelectedBills)
	}

	const getBillsCount = async () => {
		const token = localStorage.getItem('token')
		const config = getTokenHeader(token)

		try {
			const { data: { data: numberOfBills } } = await billsCount({}, config)
			setCount(numberOfBills)
		} catch (e) {
			console.log(e)
		}
	}

	const handleJWTCheck = async () => {
		const token = localStorage.getItem('token')
		if (token) {
			const config = getTokenHeader(token)
			setIsCheckingJWT(true)
			setIsBillLoading(true)

			try {
				const { data: { code, data: billsData } } = await listBills({ limit: 5, skip: 0 }, config)
				if (code) {
					setBills(billsData)
					setIsLoggedIn(true)
					getBillsCount()
				} else {
					console.log("Not autorised")
				}
			} catch (e) {
				console.log("Not autorised")
			}

			setIsBillLoading(false)
			setIsCheckingJWT(false)
		} else if (isLoggedin) {
			setIsLoggedIn(false)
			resetActionType()
			logoutRef.current.open()
		}
	}

	const handleActionClick = type => {
		if (type === ACTION_TYPE.DELETE && selectedBills.length > 0) {
			toggleActionModal()
			return
		} else if (type === ACTION_TYPE.DELETE && selectedBills.length <= 0) {
			deleteRef.current.open()
			return
		}
	}

	const handleDataTransparency = () => setIsDTVisible(!isDTVisible)

	const handleFooterClick = type => _ => {
		if (type === FOOTER_BUTTON.PRIVACY)
			props.handlePrivacy()
		else if (type === FOOTER_BUTTON.DISCLAIMER)
			props.handleDisclaimer()
		else if (type === FOOTER_BUTTON.TOS)
			props.handleTOS()
		else if (type === FOOTER_BUTTON.TRANSPARENCY)
			handleDataTransparency()
	}

	const toggleActionModal = _ => setIsActionModalVisible(!isActionModalVisible)

	const resetActionType = () => {
		if (selectedBills.length > 0)
			setSelectedBills([])

		setActionType('')
	}

	const handleDeleteClick = async _ => {
		toggleActionModal()
		const data = {
			billUid: [...selectedBills],
		}

		const token = localStorage.getItem('token')
		const config = getTokenHeader(token)

		try {
			const { data: { code } } = await deleteBills(data, config)

			if (code) {
				props.openDeleteBillsToast()
				resetActionType()
				handleJWTCheck()
				getBillsCount()
			} else {
				resetActionType()
				deleteBillsErrorRef.current.open()
			}
		} catch (e) {
			resetActionType()
			deleteBillsErrorRef.current.open()
		}
	}

	if (isCheckingJWT)
		return null

	return (
		<>
			<StyledBills direction="column" justify="space-between">
				{
					isLoggedin
						? <Main
							isBillLoading={isBillLoading}
							setIsBillLoading={setIsBillLoading}
							bills={bills}
							setBills={setBills}
							count={count}
							actionType={actionType}
							setActionType={setActionType}
							selectedBills={selectedBills}
							handleBillSelect={handleBillSelect}
							handleJWTCheck={handleJWTCheck}
						/>
						: <Login
							recaptchaRef={props.recaptchaRef}
							setVerified={setIsLoggedIn}
							phoneNumber={phoneNumber}
							setPhoneNumber={setPhoneNumber}
							getBillsCount={getBillsCount}
						/>
				}

				{
					isActionModalVisible &&
					<Modal hideDefaultCloseIcon maxWidth={600}>
						<StyledDeleteConfirmationModal>
							<div>
								<h4>Delete bills ({selectedBills.length})?</h4>
								<p>Once deleted, it can’t be fetched back. The links will de-activated as well.</p>

								<ActionArea>
									<button type="button" onClick={handleDeleteClick}>
										Delete
									</button>
									<button type="button" onClick={toggleActionModal}>
										Cancel
									</button>
								</ActionArea>
							</div>
						</StyledDeleteConfirmationModal>
					</Modal>
				}

				<BillsFooter
					handleActionClick={handleActionClick}
					actionType={actionType}
					selectedBillCount={selectedBills.length}
					handleFooterClick={handleFooterClick}
					resetActionType={resetActionType}
				/>

				<DataTransparency isVisible={isDTVisible} handleDataTransparency={handleDataTransparency} />
			</StyledBills>

			<Toast
				message="You have been logged out"
				ref={logoutRef}
			/>

			<Toast
				type="warning"
				message="Select atleast one bill to delete"
				ref={deleteRef}
			/>

			<Toast
				type="error"
				message="Error in deleting bills. Please try again"
				ref={deleteBillsErrorRef}
			/>
		</>
	)
}