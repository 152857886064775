import React from 'react'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { FlexBox, GreyText } from '../../styles/styleme'
import { StyleUtils, Color } from '../../styles'
import { UnorderedList } from '../../styles/styleme'

const transition = 'transform 0.5s ease-in-out, opacity 0.8s ease-in-out'

const ContactContainer = styled(FlexBox)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    background-color: hsla(0,0%,0%,0.5);
    color: white;
    z-index: 20;

    ${props => props.maxWidth && css`
        max-width: ${props.maxWidth}px;
    `}

    ${StyleUtils.breakpoints('mobile')`
        align-items: flex-start;
    `}
`

const CloseButton = styled.button`
    border: none;
    background-color: transparent;
    position: fixed;
    top: 100px;
    right: 100px;
    padding: 20px;
    cursor: pointer;
    border-radius: 10px;
    opacity: 0.5;
    transition: all 0.3s;
    outline: none;

    img{
        width: 30px;
        height: 30px;
    }

    &:hover, &:focus{
        background-color: hsla(240,4%,91%, 0.4);
        opacity: 1;
    }
`

const Close = ({ onCrossClick }) => (
    <CloseButton onClick={onCrossClick}>
        <img src="close-white.svg" />
    </CloseButton>
)

export const Modal = ({ onCrossClick, children, hideDefaultCloseIcon, ...rest }) => {
    return(
        <ContactContainer justify="center" align="center" {...rest}>
            {
                !hideDefaultCloseIcon &&
                <Close onCrossClick={onCrossClick} />
            }
            {children}
        </ContactContainer>
    )
}

export const ErrorMessage = ({ text, type, ...rest }) => (
    <p {...rest}
        style={{
            margin: rest.margin || type === 'empty' ? "-15px 0px 10px 10px" : "10px 0px 10px 10px",
            color: "hsl(0 100% 60%)",
            display: type === 'empty' ? "none" : "block",
            fontSize: '0.8em'
        }}
    >{text}</p>
)

export const SubmitButton = styled.button`
    background-color: ${Color.success};
    border: none;
    color: #fff;
    padding: 15px 20px;
    width: 100%;
    font-size: 14px;
    border-radius: 8px;

    &:disabled{
        background-color: hsl(0 0% 60%);
    }
`

const StyledBox = styled(FlexBox)`
    width: 90%;
    max-width: 420px;
    height: 350px;
    border: 1px solid #E1E8ED;
    box-shadow: 0px 0px 10px 10px #f6f7f9;
    margin: 2em 0 4em;
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    ${StyleUtils.breakpoints('xs')`
        padding: 2em 1.2em 1em;
    `};

    ${StyleUtils.breakpoints('sm')`
        padding: 2em 2em 1em;
    `};

    ${StyleUtils.breakpoints('md')`
        padding: 2em 4em 1em;
    `};
`

export const Process = ({ 
    page,
    onPageOneSubmit,
    onPageTwoSubmit,
    onPageTwoSecondarySubmit, // Meta handler
    onPageTwoTertiarySubmit, // Meta handler

    // Can handle only two input on both pages
    isPageOneSubmitting,
    isPageTwoSubmitting,

    // ------------------------

    pageOneErrorOne,
    pageOneErrorTwo,
    pageTwoErrorOne,
    pageTwoErrorTwo,

    // ------------------------------
    handlePageOneInputOne,
    handlePageOneInputTwo,
    handlePageTwoInputOne,
    handlePageTwoInputTwo,
    // Same for value field --------
    pageOneValueOne,
    pageOneValueTwo,
    pageTwoValueOne,
    pageTwoValueTwo,
    pageTwoValueThree, // 
    // ------------------------
    isBannerVisible,
    InformationBanner,
    PageOne,
    PageTwo,
    PageThree,

    handlePrivacy,
}) => {
    const [page1Opacity, setPage1Opacity] = React.useState(0)
    const [p1X, setP1X] = React.useState(50)
    const [page2Opacity, setPage2Opacity] = React.useState(0)
    const [p2X, setP2X] = React.useState(50)
    const [page3Opacity, setPage3Opacity] = React.useState(0)
    const [p3X, setP3X] = React.useState(50)

    const [bannerY, setBannerY] = React.useState(-100)
    const [bannerOpacity, setBannerOpacity] = React.useState(0)

    React.useEffect(() => {
        // Animate current page on load
        if(page === 1){
            setP1X(0)
            setPage1Opacity(1)
        }else if(page === 2){
            setP2X(0)
            setPage2Opacity(1)
        }
    },[])

    React.useEffect(() => {
        if(page === 1){
            // FIXME: Accurate transitions

            // Reset all and show 1
            setP1X(0)
            setPage1Opacity(1)

            if(p2X === 0){
                setP2X(-50)
                setPage2Opacity(0)
            }

        }else if(page === 2){
            setP1X(-50)
            setPage1Opacity(0)
            
            setP2X(0)
            setPage2Opacity(1)
        }else if(page === 3){
            setP2X(-50)
            setPage2Opacity(0)
            
            setP3X(0)
            setPage3Opacity(1)
        }
    },[page])

    React.useEffect(() => {
        getBannerPosition()
    },[isBannerVisible])

    const getBannerPosition = () => {
         // Show banner if visible
         if(isBannerVisible){
            setBannerY(0)
            setBannerOpacity(1)
        }else{
            setBannerY(-100)
            setBannerOpacity(0)
        }
    }

    return(
        <StyledBox direction="column" align="center">
            {
                InformationBanner &&
                <InformationBanner style={{ 
                        transform: `translateY(${bannerY}px)`,
                        opacity: bannerOpacity
                    }}
                />
            }
            <FlexBox as="form" direction="column" justify="center" style={{ 
                width: "100%", 
                transform: `translateX(${p1X}px)`, 
                opacity: page1Opacity,
                transition,
                overflow: 'hidden',
                flex: page1Opacity,
            }}>
                <PageOne 
                    onSubmit={onPageOneSubmit}
                    handlePageOneInputOne={handlePageOneInputOne}
                    handlePageOneInputTwo={handlePageOneInputTwo}
                    pageOneValueOne={pageOneValueOne}
                    pageOneValueTwo={pageOneValueTwo}
                    errorOne={pageOneErrorOne}
                    isSubmitting={isPageOneSubmitting}
                />
            </FlexBox>

            <FlexBox as="form" direction="column" justify="center" style={{ 
                width: "100%",
                transform: `translateX(${p2X}px)`, 
                opacity: page2Opacity,
                transition,
                overflow: 'hidden',
                flex: page2Opacity,
            }}>
                <PageTwo
                    onSubmit={onPageTwoSubmit}
                    onPageTwoSecondarySubmit={onPageTwoSecondarySubmit} // Meta handler
                    onPageTwoTertiarySubmit={onPageTwoTertiarySubmit}
                    isSubmitting={isPageTwoSubmitting}
                    handlePageTwoInputOne={handlePageTwoInputOne}
                    handlePageTwoInputTwo={handlePageTwoInputTwo}
                    pageTwoValueOne={pageTwoValueOne}
                    pageTwoValueTwo={pageTwoValueTwo}
                    pageTwoValueThree={pageTwoValueThree}
                    errorOne={pageTwoErrorOne}
                    errorTwo={pageTwoErrorTwo}

                    // Newly added
                    isVisible={Boolean(page2Opacity)}
                />
            </FlexBox>

            <FlexBox as="form" direction="column" justify="center" style={{ 
                width: "100%",
                transform: `translateX(${p3X}px)`, 
                opacity: page3Opacity,
                transition,
                overflow: 'hidden',
                flex: page3Opacity,
            }}>
                <PageThree />
            </FlexBox>

            {
                handlePrivacy &&
                <FlexBox justify="center" expand>
                    <GreyText as="button" onClick={handlePrivacy} fontSize="small" style={{ border: "none", backgroundColor: "#fff", padding: 0, cursor: "pointer" }}>
                        Privacy Policy    
                    </GreyText>
                </FlexBox>
            }
        </StyledBox>
    )
}

const StyledOTPSent = styled.div`
    width: 100%;
    position: absolute;
    top: 0%;
    background-color: #D1EDF2;
    text-align: center;
    transition: all 0.8s 0.5s;
`

export const OTPSent = ({ style, text }) => (
    <StyledOTPSent style={{...style}}>
        <p>{text || 'Your OTP has been sent.'}</p>
    </StyledOTPSent>
)

const Input = styled.input`
    border-radius: 20px;
    padding: 10px 20px;
    width: 100%;
    border: none;
    margin-bottom: 15px;
    box-sizing: border-box;
    font-size: 14px;

    &:last-child{
        margin-bottom: 0px;
    }
`

export const CustomInput = styled(Input)`
    &&&{
        border: 1px solid black;
        border-radius: 6px;
        margin-bottom: 20px;
        outline: none;

        &:hover, &:focus{
            border: 1px solid ${Color.base};
        }
    }
`

export const PageTitle = styled.h1`
    font-size: 2em;
    text-align: center;
    font-family: 'Clearface bold', serif;
    font-weight: 800;
    color: ${Color.text};

    ${space};

    ${StyleUtils.breakpoints('md')`
        font-size: 3em;
    `}

    span{
        color: ${Color.base};
        font-family: 'Clearface bold', serif;
        font-weight: 800;
    }
`

const ExpandButton = styled.img`
    width: 20px;
    transform: ${props => `rotate(${props.rotate})`};
    transition: transform 300ms;
`

const ContentBox = styled.div`
    height: ${props => props.height};
    overflow: hidden;
    transition: all 300ms;
`

const StyledFaq = styled(FlexBox)`
    & > div{
        width: 100%;
        max-width: 1000px;
        padding: 20px 0px;

        ${StyleUtils.breakpoints('md')`
            width: 80%;
            padding: 0;
        `}

        & > p{
            display: none;

            ${StyleUtils.breakpoints('md')`
                display: block;
            `}
        }
    }
`

const FaqListItem = styled.li`
    padding: 1em 2em;
    background-color: ${props => props.bg || "#F5F8FA"};
    margin: 10px 0px;

    ${StyleUtils.breakpoints('md')`
        padding: 1.5em 4em;
    `}

    & > button:first-child{
        border: none; 
        background-color: transparent; 
        width: 100%;
        outline: none;
    }
`


export const FAQList = ({ title, content, listColor }) => {
    const [height, setHeight] = React.useState("0px")
    const [rotate, setRotate] = React.useState("0deg")
    const [display, setDisplay] = React.useState(0)

    const contentRef = React.useRef(null)

    const handleExpand = () => {
        requestAnimationFrame(() => {
            setHeight(height === "0px" ? contentRef?.current?.offsetHeight + parseInt(contentRef?.current?.style.marginTop) + 'px' : "0px")
            setDisplay(!display)
            setRotate(height === "0px" ? "45deg" : "0deg")
        })
    }

    return(
        <FaqListItem bg={listColor}>
            <FlexBox 
                as="button" 
                justify="space-between" 
                align="center"
                p="0px" 
                onClick={handleExpand}
            >
                <h3 style={{ fontSize: "1.2rem", margin: 0, textAlign: 'left' }}>{title}</h3>
                <ExpandButton src="/add.svg" rotate={rotate} />
            </FlexBox>
            <ContentBox height={height}>
                <GreyText as="p" ref={contentRef} style={{ margin: 0, marginTop: 15 }} dangerouslySetInnerHTML={{ __html: content}} />
            </ContentBox>
        </FaqListItem>
    )
}

export const FAQ = ({ data }) => {
    return(
        <StyledFaq justify="center" p={{xs: 0, md: "5em" }}>
            <FlexBox direction="column" align="center">
                <UnorderedList expand>
                    {data.map((item, i) => 
                        <React.Fragment key={i}>
                            <FAQList title={item.title} content={item.content} />
                        </React.Fragment>
                    )}
                </UnorderedList>
            </FlexBox>
        </StyledFaq>
    )
}