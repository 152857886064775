const mediaqueries = {
    'xs': '0px', // mobile devices
    'sm': '768px', // tabs
    'md': '992px', // laptops
    'lg': '1280px', // laptops
    'xl': '1920px', // large monitors
}

const hideElement = {
    mobile: `@media screen and (max-width: 992px){
        display: none;
    }`,
    tablet: `@media screen and (max-width: 992px){
        display: none;
    }`,
    computer: `@media screen and (min-width: ${mediaqueries.md}){
        display: none;
    }`,
    laptop: `@media screen and (min-width: ${mediaqueries.lg}){
        display: none;
    }`,
    bigScreen: `@media screen and (min-width: ${mediaqueries.xl}){
        display: none;
    }`,
}

export const hideOnMobile = (props) => {
    return props.hideOnMobile && hideElement.mobile
}

export const hideOnComputer = (props) => {
    return props.hideOnComputer && hideElement.computer
}

export const hideOn = (screen) => {
    return hideElement[screen]
}

export function breakpoints(type){
    if(type === 'mobile'){
        return (param) => (`
            @media screen and (max-width: ${mediaqueries.sm}){
                ${param[0]}
            }
        `)
    }

    return (param) => (`
            @media screen and (min-width: ${mediaqueries[type]}){
                ${param[0]}
            }
        `
    )
}

export function hideOnDifferentScreens(props){
    if(props.hideOnMobile) return hideOnMobile(props)
    else return hideOnComputer(props)
}