import React from 'react'
import { StyledDT, DTNavbar,DTHeroSection } from "./styles"
import { PageTitle } from "../../styles/element"
import { FAQ } from '../common/Contact'

const data = [
    {
        title: 'What is BillMe?',
        content: `BillMe is a “digital billing” service. Businesses use BillMe to send bills digitally to their consumers via SMS/Whatsapp/Email.`,
    },
    {
        title: 'Where is my data & digital bill stored?',
        content: `When a merchant sends you a digital bill , your copy of the bill is stored securely on BillMe's servers. We store the bill on our servers so that you can have easy access to your digital bill using the link sent to you by the merchants , it can be accessed as long as you don't erase it.`,
    },
    {
        title: 'How can I access my data & digital bills',
        content: `
        <ul>
        <li>When you click on the link (Sent by the merchant) , the digital bill will open up in your web browser.</li>
        <li>BillMe has also created an online portal for users so that they can access & monitor their personal data/bills stored on BillMe's severs.</li>
        </ul>
        `,
    },
    {
        title: 'What data does BillMe store of the customer? ',
        content: `
            BillMe stores the following data against the customer's phone number:
            <ul>
                <li>
                    Digital bills sent to you by merchants using BillMe’s digital billing software.
                </li>
                <li>
                    Feedback & comments you give to the merchant via BillMe’s digital bill.
                </li>
            </ul>
        `,
    },
    {
        title: 'Does BillMe share my bills/data with third parties ?',
        content: `We do not share your personal data & bills with any third party. The bills are stored on our severs for your access only.`,
    },
    {
        title: ' Can I delete my bills/data from BillMe servers?',
        content: `
            Yes you can delete your data from BillMe servers with the following steps:
            <ul>
                <li>Click on the triple dot on the top right corner</li>
                <li>Click on “delete bills</li>
                <li>Select the bills that you want to delete</li>
                <li>Click confirm</li>
            </ul>
            <br />

            If you want to download and save the bills before deleting them from the server, follow these steps:
            <ul>
                <li>Open the digital bill using the bill link sent by the merchant or use the online portal.</li>
                <li>In the bottom right corner click on the “download bill” button. </li>
            </ul>
    
        `,
    },
]

export const DataTransparency = ({
    isVisible,
    handleDataTransparency,
}) => {

    return(
        <StyledDT translateX={isVisible ? '0' : '100%'} opacity={isVisible ? 1 : 0}>
            <DTNavbar>
                <button onClick={handleDataTransparency}>
                    <img src="arrow-left.svg" />
                    <span>Back</span>
                </button>
            </DTNavbar>
            <DTHeroSection direction="column" align="center">
                <div>
                    <PageTitle>
                        Data Transparency
                    </PageTitle>
                    <img src="data-transparency-illustration.svg" />
                    <PageTitle>
                        Here is how BillMe protects your data And gives you complete control
                    </PageTitle>
                    <p>
                        A smarter world requires a smarter solution for the age old problem of paper receipts
                    </p>
                </div>
            </DTHeroSection>
            <FAQ data={data} hideTitle hideViewMore />
        </StyledDT>
    )
}