const config = {
  PROD: {
    host: "https://user-api.yourbill.me",
    captchaKey: "6LfIy9spAAAAAPaai0cQZvJ9IU5cgUMFrgRhN6Qy",
    coralogix: {
      public_key: process.env.REACT_APP_CORALOGIX_PUBLIC_KEY,
      application: process.env.REACT_APP_CORALOGIX_APPLICATION,
    },
  },
  TEST: {
    host: "https://bills-api-development.stage.yourbill.me",
    captchaKey: "6LfIy9spAAAAAPaai0cQZvJ9IU5cgUMFrgRhN6Qy",
    coralogix: {
      public_key: process.env.REACT_APP_CORALOGIX_PUBLIC_KEY,
      application: process.env.REACT_APP_CORALOGIX_APPLICATION,
    },
  },
  DEV: {
    host: "http://localhost:7770",
    captchaKey: "6LfIy9spAAAAAPaai0cQZvJ9IU5cgUMFrgRhN6Qy",
    isDev: true,
    coralogix: {
      public_key: process.env.REACT_APP_CORALOGIX_PUBLIC_KEY,
      application: process.env.REACT_APP_CORALOGIX_APPLICATION,
    },
  },
};

module.exports = config[process.env.REACT_APP_NODE_ENV];
