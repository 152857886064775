import { host } from '../config.js'
import Axios from 'axios'

const routes = {
    // OTP Routes
    sendOtp: '/otp/sendOtp',
    resendOtp: '/otp/resendOtp',
    verifyOtp: '/otp/verifyOtp',

    // Bills Routes
    list: '/bills/list',
    delete: '/bills/delete',
    billsCount: '/bills/billsCount',
}

export const sendOtp = (phoneNumber, token) => {
    return Axios.post(host + routes.sendOtp, { phoneNumber, "g-recaptcha-response": token })
}

export const verifyOtp = (phoneNumber, otp, token) => {
    return Axios.post(host + routes.verifyOtp, { phoneNumber, otp, "g-recaptcha-response": token })
}

export const resendOtp = (phoneNumber, token) => {
    return Axios.post(host + routes.resendOtp, { phoneNumber, "g-recaptcha-response": token })
}

export const listBills = (data, options) => {
    return Axios.post(host + routes.list, {...data}, {...options})
}

export const billsCount = (data, options) => {
    return Axios.post(host + routes.billsCount, {...data}, {...options})
}

export const deleteBills = (data, options) => {
    return Axios.post(host + routes.delete, {...data}, {...options})
}