export const base = "#12B9F3"

export const text = "#043140"

export const footer = "#107E8C"

export const success = "#18AA28"
export const successDark = "#11831D"

export const monochrome = {
    light: "#F6F6F6"
}

export const primary = {
    default: '#3B86FC'
}