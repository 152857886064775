import React from "react"
import ReactPaginate from "react-paginate"
import {
  FlexBox,
  UnorderedList,
  GreyText,
  Skeleton,
} from "../../styles/styleme"
import {
  StyledBillRow,
  ButtonLink,
  Menu,
  MainHeading,
  MainContainer,
} from "./styles"
import { listBills } from "../../api/actions"
import { getTokenHeader } from "../../utils/getConfig"
import RZPBMLogo from "../../rzpbm-logo-Light.svg"

const Header = ({ billCount, setActionType, handleJWTCheck }) => {
  const [isMenuVisible, setIsMenuVisible] = React.useState(false)

  const handleMenuClick = () => setIsMenuVisible(!isMenuVisible)

  const handleItemClick = (type) => (_) => {
    setActionType(type)
    handleMenuClick()
  }

  const handleLogout = () => {
    localStorage.removeItem("token")
    handleJWTCheck()
  }

  return (
    <FlexBox
      justify="space-between"
      align="center"
      p="5px 10px 5px 20px"
      style={{
        borderBottom: "1px solid #ccc",
        position: "relative",
      }}
    >
      <p>Your Bills ({billCount})</p>
      <ButtonLink onClick={handleMenuClick} style={{ padding: "5px 20px" }}>
        <img src="dot-menu.svg" />
      </ButtonLink>
      <Menu
        opacity={isMenuVisible ? 1 : 0}
        height={isMenuVisible ? "auto" : "0px"}
      >
        <UnorderedList>
          <li>
            <button onClick={handleItemClick("delete")}>Delete</button>
          </li>
          {/* <li>
                        <button onClick={handleItemClick('share')}>
                            Share
                        </button>
                    </li> */}
          <li>
            <button onClick={handleLogout}>Logout</button>
          </li>
        </UnorderedList>
      </Menu>
    </FlexBox>
  )
}

const BillRow = ({
  item: {
    createdDate,
    other: { brandLogo, brandName, displayAddress },
    shortId,
  },
  actionType,
  id,
  selectedBills = [],
  handleBillSelect,
}) => {
  const date = new Date(createdDate)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return (
    <StyledBillRow
      as="a"
      href={`https://yourbill.me/${shortId}`}
      target="_blank"
    >
      <FlexBox
        as="label"
        htmlFor={id}
        justify="space-between"
        p="20px 0px"
        expand
      >
        <FlexBox align="center">
          {actionType && (
            <input
              type="checkbox"
              id={id}
              onChange={handleBillSelect(id)}
              checked={selectedBills.includes(id)}
            />
          )}
          <img src={brandLogo} alt={brandName} />
          <div>
            <p>{brandName}</p>
            <p>{displayAddress}</p>
          </div>
        </FlexBox>
        <div>
          <p>{`${day}/${month}/${year}`}</p>
          <p>{`${hours}: ${minutes}`}</p>
        </div>
      </FlexBox>
    </StyledBillRow>
  )
}

const BillsList = (props) => {
  return (
    <div style={{ maxHeight: "450px", overflow: "auto" }}>
      {props.bills &&
        props.bills.length > 0 &&
        props.bills.map((item) => (
          <BillRow
            key={item.billUid}
            item={item}
            actionType={props.actionType}
            id={item.billUid}
            selectedBills={props.selectedBills}
            handleBillSelect={props.handleBillSelect}
          />
        ))}
    </div>
  )
}

export const Main = ({
  isBillLoading,
  setIsBillLoading,
  bills,
  setBills,
  count,
  selectedBills,
  actionType,
  setActionType,
  handleBillSelect,
  handleJWTCheck,
}) => {
  React.useEffect(() => {
    if (!bills) getUserBills(0)
  }, [])

  const getUserBills = async (skip) => {
    const token = localStorage.getItem("token")
    const config = getTokenHeader(token)

    setIsBillLoading(true)

    try {
      const res = await listBills({ limit: 5, skip }, config)
      setBills(res.data.data)
    } catch (e) {
      console.log(e)
    }

    setIsBillLoading(false)
  }

  const handlePageChange = ({ selected }) => {
    getUserBills(selected * 5)
  }

  return (
    <MainContainer>
      <Header
        billCount={count}
        setActionType={setActionType}
        handleJWTCheck={handleJWTCheck}
      />
      <MainHeading p="10px 20px">
        Here are all your bills received from merchants using{" "}
        <span>digital</span> billing
      </MainHeading>
      <div style={{ padding: "10px 20px" }}>
        <p
          style={{
            padding: "10px 0px",
            borderBottom: "1px solid #DADADA",
            margin: 0,
          }}
        >
          Your Bills
        </p>
        {isBillLoading ? (
          <Skeleton count={5} height={65} />
        ) : bills && bills.length > 0 ? (
          <BillsList
            bills={bills}
            actionType={actionType}
            selectedBills={selectedBills}
            handleBillSelect={handleBillSelect}
          />
        ) : (
          <GreyText style={{ textAlign: "center", marginTop: "20px" }}>
            No Bills found
          </GreyText>
        )}
      </div>
      {bills && bills.length > 0 && (
        <ReactPaginate
          pageCount={Math.ceil(count / 5)}
          pageRangeDisplayed={1}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          previousLabel="<"
          nextLabel=">"
          containerClassName="bills-pagination"
          pageClassName="bills-pagination--page"
          activeClassName="bills-pagination--page__active"
          activeLinkClassName="bills-pagination--page--link__active"
        />
      )}
      <img
        src={RZPBMLogo}
        width="160px"
        alt=""
        style={{
          position: "relative",
          display: "block",
          margin: "auto",
          marginTop: "70px",
          marginBottom: "70px"
        }}
      />
    </MainContainer>
  )
}
