import React from 'react';
import './App.css';
import { Default } from './styles/styleme';
import { Bills } from '../src/components/bills'
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './styles/element';
import { Privacy, Disclaimer, TOS } from './components/common/Conditions';
import axios from 'axios'
axios.defaults.withCredentials = true
function App() {
	const [isPrivacyVisible, setIsPrivacyVisible] = React.useState(false)
	const [isDisclaimerVisible, setIsDisclaimerVisible] = React.useState(false)
	const [isTOSVisible, setIsTOSVisible] = React.useState(false)

	const handleDisclaimer = () => setIsDisclaimerVisible(!isDisclaimerVisible)

	const handlePrivacy = () => setIsPrivacyVisible(!isPrivacyVisible)
	const handleTOS = () => setIsTOSVisible(!isTOSVisible)
	const handleGrievance = () => { window.location.href = '' }

	return (
		<ThemeProvider theme={Default}>
			<GlobalStyle />
			<Bills handleDisclaimer={handleDisclaimer} handlePrivacy={handlePrivacy} handleTOS={handleTOS} handleGrievance={handleGrievance} />
			{
				isPrivacyVisible &&
				<Privacy onCrossClick={handlePrivacy} />
			}
			{
				isDisclaimerVisible &&
				<Disclaimer onCrossClick={handleDisclaimer} />
			}
			{
				isTOSVisible &&
				<TOS onCrossClick={handleTOS} />
			}
		</ThemeProvider>
	);
}

export default App;
